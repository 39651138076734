import React from 'react';
import PropTypes from 'prop-types';

import { EMBED } from '../configs/global';

const MapWrapperRightPanel = ({ children }) => (
  <div className={!EMBED ? 'main__results' : 'main__results main__results--embed'}>
    <div className={!EMBED ? 'route-finder-map' : 'route-finder-map route-finder-map--embed'}>
      {children}
    </div>
  </div>
);

MapWrapperRightPanel.propTypes = {
  children: PropTypes.element.isRequired
};

export default MapWrapperRightPanel;
