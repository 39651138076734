import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import fetchJsonp from 'fetch-jsonp';
import { v4 } from 'uuid';
import L from 'leaflet';
import { Map as LeafletMap, Marker, Circle, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { EMBED } from '../../configs/global';

import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';

import 'leaflet-easybutton';
import 'leaflet-easybutton/src/easy-button.css';

import 'mapbox-gl-leaflet';
import 'mapbox-gl';

import 'font-awesome/css/font-awesome.min.css';

import RouteFinderSearch from './Search';
import CustomMarker from '../../components/Marker';
import MapWrapperRightPanel from '../../components/MapWrapperRightPanel';

import { LONG_API_URL, SHORT_API_URL, DEFAULT_VIEWPORT, MAX_ZOOM } from '../../constants/common';
import { getCategoryIconMarker, getCircleTooltipLatLng } from '../../utils/map';

import withTracker from '../withTracker';

import iconPin from '../../assets/images/route-finder/map-marker.svg'

require('react-leaflet-markercluster/dist/styles.min.css');

let lc;
class MapWithProvider extends LeafletMap {
  createLeafletElement(props) {

    const IconPin = {
      iconUrl: iconPin,
      iconRetinaUrl: iconPin,
      iconAnchor: null,
      popupAnchor: [0, -30],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(40, 40)
    };
    
  
    const LeafletMapElement = super.createLeafletElement(props);
    lc = L.control.fullscreen().addTo(LeafletMapElement);
    L.Control.MyLocate = L.Control.Locate.extend({
      _drawMarker: function() {
        var latlng = this._event.latlng;
        if (!this._circle) {
          this._circle = L.circle(latlng, {radius: 8000, color: '#f46e70', fill: false, weight: 2}).addTo(this._layer);
          this._circleA = L.circle(latlng, {radius: 16000, color: '#f46e70', fill: false, weight: 1.3 }).addTo(this._layer);
          this._circleB = L.circle(latlng, 40000, {color: '#f46e70', fill: false, weight: 0.8 }).addTo(this._layer);
          this._circleTooltip = L.circle(getCircleTooltipLatLng(latlng, 8000), 0, {color: '#f46e70', fill: false}).bindTooltip('5 miles', {permanent: true, direction: 'right'}).addTo(this._layer);
          this._circleATooltip = L.circle(getCircleTooltipLatLng(latlng, -16000), 0, {color: '#f46e70', fill: false}).bindTooltip('10 miles', {permanent: true, direction: 'left'}).addTo(this._layer);
          this._circleBTooltip = L.circle(getCircleTooltipLatLng(latlng, 40000), 0, {color: '#f46e70', fill: false}).bindTooltip('25 miles', {permanent: true, direction: 'right'}).addTo(this._layer);
        } else {
          this._circle.setLatLng(latlng);
          this._circleA.setLatLng(latlng);
          this._circleB.setLatLng(latlng);
          this._circleTooltip.setLatLng(getCircleTooltipLatLng(latlng, 8000));
          this._circleATooltip.setLatLng(getCircleTooltipLatLng(latlng, -16000));
          this._circleBTooltip.setLatLng(getCircleTooltipLatLng(latlng, 40000));
        }

        if (!this._marker) {
          this._marker = new this.options.markerClass(latlng, this.options.markerStyle).addTo(this._layer);
        } else {
          this._marker.setLatLng(latlng);
        }
      },
      _removeMarker: function() {
        this._layer.clearLayers();
        this._marker = undefined;
        this._circle = undefined;
        this._circleTooltip = undefined;
        this._circleA = undefined;
        this._circleATooltip = undefined;
        this._circleB = undefined;
        this._circleBTooltip = undefined;
      },
    });
    lc = new L.Control
      .MyLocate({
        locateOptions: {
          maxZoom: MAX_ZOOM - 1
        },
        drawCircle: false,
        showCompass: false,
        drawMarker: false,
        markerClass: L.marker,
        markerStyle: { 
            icon: L.icon( IconPin ) 
        }
      })
      .addTo(LeafletMapElement);
      window.locationButton = L.easyButton({
        states: [{
          stateName: 'inactive',
          icon: 'fa-map-marker',
          title: 'Show me where I am',
          onClick: function(control) {
            if (document.getElementById('search-input').value)
              document.getElementsByClassName('coach-search-clearable')[0].click();
            document.getElementsByClassName('coach-search-clearable')[0].click();
          }
        }, {
          icon: 'fa-map-marker',
          stateName: 'active',
          onClick: function(control) {
            document.getElementsByClassName('coach-search-clearable')[0].click();
          },
          title: 'Disable Location'
        }]
      }).addTo(LeafletMapElement);
    L.mapboxGL({
      attribution:
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      accessToken: 'pk.eyJ1IjoidXRyYWNrbWFwIiwiYSI6Im9iV245NnMifQ.ddr0eLkRuZUoNVglI8tZvg',
      style: 'https://api.maptiler.com/maps/basic/style.json?key=qFLLNZGl77ciGk4TxKUS'
    }).addTo(LeafletMapElement);

    return LeafletMapElement;
  }
}

class RouteFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUserLocation: false,
      isClusterMode: true,
      allStops: [],
      currentStops: [],
      viewport: DEFAULT_VIEWPORT,
      pinIcon: { enabled: false },
      stopName: null
    };
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.location) {
      this.fetchSearchStops(match.params.location).then(response => {
        let found = response.find(function (element) {
          return element.linkName === match.params.location;
        });

        if (found) {
          this.fetchNearestStops(found.latitude, found.longitude, true, match.params.zoom ? match.params.zoom : false);
          this.setState({ stopName : found.name })
        }
        else if (response[0].name)
          this.fetchNearestStops(response[0].latitude, response[0].longitude, true, match.params.zoom ? match.params.zoom : false);

      });
    } else {
      this.fetchAllStops();
    }
  }

  fetchAllStops = () => {
    fetchJsonp(`${LONG_API_URL}/static/stops`)
      .then(response => response.json())
      .then(response => {
        this.setState({ allStops: response, currentStops: response });
      })
      .catch(error => console.error(error));
  };

  fetchNearestStops = (latitude, longitude, isSearchSelect = false, zoom = false) => {
    const fixedLatitude = latitude.toFixed(5);
    const fixedLongitude = longitude.toFixed(5);

    this.setState({ isLoading: true }, () => {
      fetchJsonp(
        `${LONG_API_URL}/${
          isSearchSelect ? 'static/stops' : `search?lookup=${fixedLatitude},${fixedLongitude}&mode%5B%5D=nearest_stop&limit=8`
        }`
      )
        .then(response => response.json())
        .then(response => {
          if (!response.status && response.status !== 'ERROR') {
            this.setState(
              {
                currentStops: response,
                isLoading: false,
                isClusterMode: !isSearchSelect
              },
              () => {
                let pinIcon = { enabled: false }
                if (response.length) {
                  if (isSearchSelect) {
                    this.setLocation(latitude, longitude, zoom ? zoom : 10);
                    if (response.length > 1) {
                      pinIcon = { enabled: true, latlng: [latitude, longitude] }
                    }
                  } else
                    this.setLocation(latitude, longitude);
                } else {
                  this.setLocation(latitude, longitude, zoom ? zoom : 10);
                  pinIcon = { enabled: true, latlng: [latitude, longitude] }
                }
                this.setState(
                  {
                    pinIcon: pinIcon
                  }
                )
              }
            );
          }
        });
    });
  };

  fetchSearchStops = async (lookup, limit = 10) => {
    try {
      let response = await fetchJsonp(
        `${SHORT_API_URL}/search?lookup=${encodeURIComponent(lookup)}&mode%5B%5D=route_finder&limit=${limit}`
      );
      return await response.json();
    } catch (err) {
      console.error(err);
    }
  };

  handleSearchSelect = station => {
    const { latitude, longitude } = station;

    this.fetchNearestStops(latitude, longitude, true);
  };

  setFitBounds = bounds => {
    if (bounds && bounds.length && bounds[0][0]) {
      this.mapRef.leafletElement.fitBounds(bounds, {
        padding: [30, 30]
      });
    }
  };

  setLocation = (latitude, longitude, zoom = 0) => {
    this.setState({
      viewport: {
        center: [latitude, longitude],
        zoom: zoom ? zoom : MAX_ZOOM - 1
      }
    });
  };

  onLocationFound = ({ latitude, longitude }) => {
    this.setLocation(latitude, longitude);

    if (!this.state.isLoading) {
      this.fetchNearestStops(latitude, longitude);
    }
  };

  findUserLocation = () => {
    lc.start();
    window.locationButton.state('active');
    this.setState({ activeUserLocation: true });
  };

  resetUserLocation = () => {
    lc.stop();
    window.locationButton.state('inactive');
    const { match } = this.props;
    if (EMBED && match.params.location) {
      this.setState(state => ({
        activeUserLocation: false
      }));
      if (this.state.pinIcon.enabled)
      this.mapRef.leafletElement.setView([this.state.pinIcon.latlng[0], this.state.pinIcon.latlng[1]], match.params.zoom ? match.params.zoom : 10);
    } else {
      this.mapRef.leafletElement.setView(DEFAULT_VIEWPORT.center, DEFAULT_VIEWPORT.zoom);
      this.props.history.push('/');
      this.setState(state => ({
        isClusterMode: true,
        activeUserLocation: false,
        viewport: DEFAULT_VIEWPORT,
        currentStops: state.allStops,
        pinIcon: { enabled: false }
      }));
    }
  };

  render() {
    const { isMobile, match } = this.props;
    const { activeUserLocation, currentStops, isClusterMode, pinIcon, stopName } = this.state;
    return (
      <Fragment>
        {match.params.location && (
          <Helmet titleTemplate={`All locations near, ${stopName ? stopName : match.params.location}  - %s`}>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        )}

        <div className="route-finder">
          <RouteFinderSearch
            isMobile={isMobile}
            activeUserLocation={activeUserLocation}
            fetchSearchStops={this.fetchSearchStops}
            handleSearchSelect={this.handleSearchSelect}
            findUserLocation={this.findUserLocation}
            resetUserLocation={this.resetUserLocation}
            {...this.props}
          />

          <MapWrapperRightPanel>
            <MapWithProvider
              viewport={this.state.viewport}
              maxZoom={MAX_ZOOM}
              attributionControl={true}
              zoomControl={true}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
              className="markercluster-map"
              ref={ref => (this.mapRef = ref)}
            >
              {pinIcon.enabled && !activeUserLocation && (
                [
                  <Marker key={1} icon={getCategoryIconMarker('iconPin')} position={pinIcon.latlng} zIndexOffset={399}></Marker>,
                  <Circle key={2} center={pinIcon.latlng} radius={8000} color={'#f46e70'} fill={false} weight={2}></Circle>,
                  <Circle key={3} center={getCircleTooltipLatLng(pinIcon.latlng, 8000)} radius={0} color={'#f46e70'}>
                    <Tooltip permanent={true} direction={'right'}>5 miles</Tooltip>
                  </Circle>,
                  <Circle key={4} center={pinIcon.latlng} radius={16000} color={'#f46e70'} fill={false} weight={1.3}></Circle>,
                  <Circle key={5} center={getCircleTooltipLatLng(pinIcon.latlng, -16000)} radius={0} color={'#f46e70'}>
                    <Tooltip permanent={true} direction={'left'}>10 miles</Tooltip>
                  </Circle>,
                  <Circle key={6} center={pinIcon.latlng} radius={40000} color={'#f46e70'} fill={false} weight={0.8}></Circle>,
                  <Circle key={7} center={getCircleTooltipLatLng(pinIcon.latlng, 40000)} radius={0} color={'#f46e70'}>
                    <Tooltip permanent={true} direction={'right'}>25 miles</Tooltip>
                  </Circle>
                ]
              )}
              {isClusterMode ? (
                <MarkerClusterGroup showCoverageOnHover={false} maxClusterRadius={50}>
                  {currentStops.map((stop, index) => (
                    <CustomMarker
                      zIndexOffset={899}
                      isOpenMarker={!index && (!pinIcon.enabled && !activeUserLocation) ? true : false}
                      key={v4()}
                      icon={getCategoryIconMarker(stop.category)}
                      stop={stop}
                      {...this.props}
                    />
                  ))}
                </MarkerClusterGroup>
              ) : (
                currentStops.map((stop, index) => (
                  <CustomMarker
                    zIndexOffset={899}
                    isOpenMarker={!index && (!pinIcon.enabled && !activeUserLocation) ? true : false}
                    key={v4()}
                    icon={getCategoryIconMarker(stop.category)}
                    stop={stop}
                    {...this.props}
                  />
                ))
              )}
            </MapWithProvider>
          </MapWrapperRightPanel>
        </div>
      </Fragment>
    );
  }
}

export default withTracker(RouteFinder);
