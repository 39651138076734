import React from 'react';
import { Marker, Popup } from 'react-leaflet';

import IconMapMarker from '../assets/images/icon-components/IconMapMarker';
import IconTooltipInfo from '../assets/images/icon-components/IconTooltipInfo';
import IconWheelChair from '../assets/images/icon-components/IconWheelChair.svg';

import { EMBED } from '../configs/global';

const getStopName = (str, mobileView = false) => {
  if (str) {
    // eslint-disable-next-line
    let arr = [];
    let positions = [];

    if (str.indexOf(':') !== -1) {
      arr = [
        str.substring(0, str.indexOf(':') + 1).replace(':', ''),
        str
          .substring(str.indexOf(':') + 1)
          .replace(':', '')
          .replace(',', ' ')
          .replace(/\s*\[.*?\]\s*/g, '')
      ];
      positions = [];

      if (mobileView && arr[0].length > 20) {
        for (let i = 0; i < arr[0].length; i++) {
          if (arr[0][i].match(/[a-z]/) != null) {
            positions.push(i);
          }
        }

        const index = positions[0] - 1;

        arr = [arr[0].substring(0, index), arr[0].substring(index), arr[1]];
      }

      return arr;
    }

    if (str.indexOf(',') !== -1) {
      arr = [
        str.substring(0, str.indexOf(',') + 1).replace(',', ''),
        str.substring(str.indexOf(',') + 1).replace(',', '')
      ];
      positions = [];

      if (mobileView && arr[0].length > 20) {
        for (let i = 0; i < arr[0].length; i++) {
          if (arr[0][i].match(/[a-z]/) != null) {
            positions.push(i);
          }
        }

        const index = positions[0] - 1;

        arr = [arr[0].substring(0, index), arr[0].substring(index), arr[1]];
      }

      return arr;
    }

    if (str.indexOf('(') !== -1 && str.length > 20) {
      return (arr = [str.substring(0, str.indexOf('(') - 1), str.substring(str.indexOf('(') - 1)]);
    }

    if (str.length > 20) {
      for (let i = 0; i < str.length; i++) {
        if (str[i].match(/[a-z]/) != null) {
          positions.push(i);
        }
      }

      let index = positions[0] - 1;

      arr = [str.substring(0, index), str.substring(index)];

      if (mobileView && arr[1].length > 20) {
        let positions = [];
        for (let i = 0; i < arr[1].length; i++) {
          if (arr[1][i].match(/[A-Z]/g) != null) {
            positions.push(i);
          }
        }
        const index = positions[1];
        arr = [arr[0], arr[1].substring(0, index), arr[1].substring(index)];
      }

      return arr;
    }

    return [str];
  }

  return ['Unknow'];
};

const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

const reloadMapIOS = () => {
  if (isIOS()) {
    setTimeout(() => {
      window.location.reload();
    }, 150);
  }
};

const ExtendedMarker = props => {
  const initMarker = ref => {
    if (ref && props.isOpenMarker) {
      ref.leafletElement.openPopup();
    }
  };

  return <Marker ref={initMarker} {...props} />;
};

class CustomMarker extends React.Component { 
  render() {
   const { icon, stop, zIndexOffset, isDisabledBtn, isOpenMarker, ...props } = this.props
   return (
    stop.latitude === 0 && stop.longitude === 0 ? null : <ExtendedMarker
      icon={icon}
      position={[stop.latitude, stop.longitude]}
      isOpenMarker={isOpenMarker}
      zIndexOffset={zIndexOffset}
    >
      <Popup minWidth={260} closeButton={true}>
        <div className={!EMBED ? 'marker__popup mainNotes' : 'marker__popup mainNotes mainNotes--embed'}>
          <div className="title">
            <span>{getStopName(stop.ticketName ? stop.ticketName : stop.name)[0]}</span>
            <span
              className={
                getStopName(stop.ticketName ? stop.ticketName : stop.name)[0] ? 'address' : ''
              }
            >
              {getStopName(stop.ticketName ? stop.ticketName : stop.name, false).length > 1
                ? getStopName(stop.ticketName ? stop.ticketName : stop.name)[1]
                : null}
            </span>
            <span>
              {getStopName(stop.ticketName, false).length > 2
                ? getStopName(stop.ticketName ? stop.ticketName : stop.name)[2]
                : null}
            </span>
          </div>

          <div className="info">
            <div className="info__text">
              <div>
                <i>
                  <IconMapMarker />
                </i>
                <a
                  href={`https://www.google.com/maps/place/${stop.latitude},${stop.longitude}`}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Directions to Stop
                </a>
                {stop.wheelchairAccessible && (
                  <div className="info__icon">
                    <img src={IconWheelChair} alt="Wheelchair Accessible" />
                  </div>
                )}
              </div>
            </div>

            {stop.notes && <div className="info">
              <div className="info__notes">
                  <div>
                    <i>
                      <IconTooltipInfo />
                    </i>
                    Please carefully read the details below to ensure that you are at the correct departure point:
                  </div>
                  <div>
                    <span dangerouslySetInnerHTML={{ __html: stop.notes ? stop.notes : "No stop information available" }} />
                  </div>
              </div>
            </div>}
          </div>

          {stop.category !== 6 && !isDisabledBtn && !EMBED && (
            <div className="btns">
              <button
                className="btns__green"
                onClick={() => {
                  props.history.push(
                    stop.selectedToStopId
                      ? `/search/between/${stop.linkName ? stop.linkName : stop.id}/${stop.selectedToStopId}`
                      : `/search/from/${stop.linkName ? stop.linkName : stop.id}`
                  );
                  reloadMapIOS();
                }}
              >
                Travel from here
              </button>
              <button
                className="btns__blue"
                onClick={() => {
                  props.history.push(
                    stop.selectedFromStopId
                      ? `/search/between/${stop.selectedFromStopId}/${stop.linkName ? stop.linkName : stop.id}`
                      : `/search/to/${stop.linkName ? stop.linkName : stop.id}`
                  );
                  reloadMapIOS();
                }}
              >
                Travel to here
              </button>
            </div>
          )}
        </div>
      </Popup>
    </ExtendedMarker>
   )
  }
}


export default CustomMarker;
