import React from 'react';
import { connect } from 'react-redux';
import ReactPopup from 'reactjs-popup';
import ReCAPTCHA from 'react-google-recaptcha';

import { toggleFeedbackPopupHidden } from '../redux/popup/popup.actions';

import IconClosePopupBtn from '../assets/images/icon-components/IconClosePopupBtn';

const ResetPopupStyles = {
  padding: 0
};

const PopupFeedback = ({ feedbackPopupHidden, dispatch }) => {
  const closePopup = () => {
    dispatch(toggleFeedbackPopupHidden(true));
  };

  return (
    <ReactPopup
      open={!feedbackPopupHidden}
      modal
      closeOnDocumentClick
      style={ResetPopupStyles}
      onClose={closePopup}
      lockScroll={true}
      className="feedback-popup"
    >
      <div className="popup-wrapper popup-feedback__wrapper">
        <div className="btn-close" onClick={closePopup}>
          <IconClosePopupBtn />
        </div>

        <div className="title blue">Feedback</div>
        <p className="caption">
          This is for feedback purposes only. <br /> A response will not be provided
        </p>

        <FeedbackForm close={closePopup} />
      </div>
    </ReactPopup>
  );
};

const recaptchaRef = React.createRef();

class FeedbackForm extends React.Component {
  state = {
    isSubmited: false,
    email: '',
    phone: '',
    message: '',
    name: '',
    isCaptcha: false
  };

  onSubmit = async event => {
    const { email, name, phone, feedback } = this.state;
    event.preventDefault();
    fetch('https://nx-vanguard.utrackapps.com/dev/feedback/feedback.php', {
      method: 'POST',
      body: JSON.stringify({
        app: 'RouteFinder',
        email: email,
        name: name,
        phone: phone,
        message: feedback,
        "g-recaptcha-response": recaptchaRef.current.getValue()
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });
    this.props.close();
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  onChangeCaptcha = value => {
    this.setState({ isCaptcha: !!value });
  };

  render() {
    const { close } = this.props;
    const { isCaptcha, email, name, phone, feedback } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-placeholder">
          <input type="email" name="email" value={email} onChange={this.handleChange} required />
          {!email && (
            <div className="placeholder">
              Email <span>*</span>
            </div>
          )}
        </div>

        <div className="input-placeholder">
          <input type="text" name="name" value={name} onChange={this.handleChange} />
          {!name && <div className="placeholder">Name (optional)</div>}
        </div>

        <div className="input-placeholder">
          <input type="tel" name="phone" value={phone} onChange={this.handleChange} />
          {!phone && <div className="placeholder">Phone (optional)</div>}
        </div>

        <div className="input-placeholder">
          <textarea
            name="feedback"
            cols="30"
            rows="5"
            value={feedback}
            onChange={this.handleChange}
            required
          ></textarea>
          {!feedback && (
            <div className="placeholder">
              Feedback <span>*</span>
            </div>
          )}
        </div>

        <div className="section-submit">
          <small>
            <span className="blue">*</span> Mandatory fields
          </small>

          <ReCAPTCHA
            className="captcha"
            ref={recaptchaRef}
            sitekey={'6LeYCl0UAAAAAL0MTszSXyia3-umhlIcded3_m13'}
            onChange={this.onChangeCaptcha}
            size="normal"
          />

          <div className="btns">
            <button className="button button--gray" onClick={close}>
              Cancel
            </button>
            <button className="button button--green" disabled={!isCaptcha} type="submit">
              Submit
            </button>
          </div>

          <p>
            If you need to get in touch about your journey, please click{' '}
            <a
              href="https://www.nationalexpress.com/en/help/contact"
              className="blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
          </p>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  feedbackPopupHidden: state.popupReducer.feedbackPopupHidden
});

export default connect(mapStateToProps)(PopupFeedback);
