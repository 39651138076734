import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

import { THEME } from '../configs/global';

let gkey = THEME.GA.KEY;

if (window.location.hostname === 'routemap.nationalexpress.com' || window.location.hostname === 'routemap-embed.nationalexpress.com') {
  gkey='UA-22502992-7';
}

let gtmkey = 'GTM-NPWVLLL'

if (gkey) {
  ReactGA.initialize(gkey);
}

const setPageTitle = ({ match, fromData, toData }) => {
  if (match.params.from && !match.params.to && fromData) {
    return `Services from, ${fromData.ticketName}  - ${THEME.TITLE}`;
  } else if (!match.params.from && match.params.to && toData) {
    return `Services to, ${toData.ticketName}  - ${THEME.TITLE}`;
  } else if (match.params.from && match.params.to && fromData && toData) {
    return `Services between, ${fromData.ticketName} and ${toData.ticketName} - ${THEME.TITLE}`;
  } else if (match.params.location) {
    return `All locations near, ${match.params.location} - ${THEME.TITLE}`;
  } else {
    return THEME.TITLE;
  }
};

const withTracker = (WrappedComponent, options = {}) => {
  const tagManagerArgs = {
    gtmId: gtmkey
  }  

  const trackPage = props => {
    if (props.fromData !== null || props.toData !== null) {
      const pagePath = props.location.pathname;
      const pageTitle = setPageTitle(props);

      ReactGA.set({
        page: pagePath,
        title: pageTitle,
        ...options
      });
      ReactGA.pageview(pagePath, [], pageTitle);
    }
    TagManager.initialize(tagManagerArgs);
  };

  const HOC = props => {
    useEffect(() => trackPage(props), [props]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
