import React, { useState, useEffect } from 'react';

import RouteFinderPopupInfo from './PopupInfo';

import { EMBED } from '../configs/global';

import iconBackToTop from '../assets/images/route-finder/back-to-top.svg';

const wrapperStyle = {
  width: '100%',
  position: 'relative'
};

const SearchLeftPanel = ({ children }) => {
  const [isShowingBackToTop, setBackToTop] = useState(!!window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 30) {
      setBackToTop(true);
    } else {
      setBackToTop(false);
    }
  };

  const scrollToTop = () => {
    try {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    } catch {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={!EMBED ? 'main__search' : 'main__search main__search--embed'}>
      <form className={`coach-search route-finder-${!EMBED ? 'search' : 'search--embed'}`}>
        <h3 className="route-finder-search-title">Route Map</h3>
        <p className="route-finder-search-label">
          Please use search box below or interact with the map to find a stop
        </p>

        <div style={wrapperStyle}>{children}</div>
      </form>

      <RouteFinderPopupInfo />

      {isShowingBackToTop && (
        <img onClick={scrollToTop} className="icon-back-to-top" src={iconBackToTop} alt="top" />
      )}
    </div>
  );
};

export default SearchLeftPanel;
