import React, { Component, Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { v4 } from 'uuid';
import L from 'leaflet';
import { Map as LeafletMap, FeatureGroup, Polyline } from 'react-leaflet';

import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';

import 'mapbox-gl-leaflet';
import 'mapbox-gl';

import polyUtil from 'polyline-encoded';

import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

import CustomMarker from '../../components/Marker';

import { DEFAULT_VIEWPORT, MAX_ZOOM } from '../../constants/common';
import { getCategoryIconMarker } from '../../utils/map';
import { convertMinsToHrsMins } from '../../utils/time';

import iconClearInput from '../../assets/images/clear-search-icon.svg';

import withTracker from '../withTracker';

class MapWithProvider extends LeafletMap {
  createLeafletElement(props) {
    const LeafletMapElement = super.createLeafletElement(props);

    const mainSearch = document.getElementsByClassName('main__search')[0];
    const routeFinderMapHeader = document.getElementsByClassName('route-finder-map-header')[0];

    L.control.fullscreen().addTo(LeafletMapElement);

    LeafletMapElement.on('enterFullscreen', function() {
      mainSearch.style.display = 'none';
      routeFinderMapHeader.style.display = 'none';
    });
    LeafletMapElement.on('exitFullscreen', function() {
      mainSearch.style.display = 'block';
      routeFinderMapHeader.style.display = 'block';
    });

    L.mapboxGL({
      attribution:
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      accessToken: 'pk.eyJ1IjoidXRyYWNrbWFwIiwiYSI6Im9iV245NnMifQ.ddr0eLkRuZUoNVglI8tZvg',
      style: 'https://api.maptiler.com/maps/basic/style.json?key=qFLLNZGl77ciGk4TxKUS'
    }).addTo(LeafletMapElement);

    return LeafletMapElement;
  }
}

class SearchServiceMap extends Component {
  state = {
    polylines: [],
    markers: [],
    currentMarker: [],
    activeRoute: {},
    isBetween: false,
    isShowingPolylines: true,
    direction: null
  };

  componentDidUpdate(prevProps) {
    const { fromData, toData, stations, bounds } = this.props;

    if (prevProps.fromData !== fromData && !toData) {
      this.getMarkersAndPolylines(stations, fromData, 'from');
    }
    if (prevProps.toData !== toData && !fromData) {
      this.getMarkersAndPolylines(stations, toData, 'to');
    }
    if (prevProps.fromData !== fromData && prevProps.toData !== toData) {
      this.getMarkersAndPolylines(stations, [fromData, toData], 'between');
    }

    if (prevProps.bounds !== bounds && bounds.length) {
      this.setFitBounds(bounds);
    }
  }

  getMarkersAndPolylines = (data, currentMarker, direction) => {
    const tempMarkers = [];
    const tempPolylines = [];
    const duplicates = [];

    if (currentMarker && (currentMarker.id || (currentMarker[0] && currentMarker[1]))) {
      if (direction !== 'between') {
        duplicates.push(currentMarker.id);
      } else {
        currentMarker.map(stop => duplicates.push(stop.id));
      }

      data &&
        data.forEach(route => {
          // get All polylines
          tempPolylines.push({
            id: route.id,
            name: route.name,
            arrival: route.arrival,
            duration: route.duration,
            positions: route.journeyStops
              .filter(stop => stop.polyline)
              .map(stop => polyUtil.decode(stop.polyline))
          });

          // get All markers
          route.journeyStops &&
            route.journeyStops.forEach(stop => {
              if (direction !== 'between') {
                if (
                  currentMarker &&
                  stop.id !== currentMarker.id &&
                  !duplicates.includes(stop.id)
                ) {
                  tempMarkers.push({
                    ...stop,
                    id: stop.id,
                    routeId: [route.id],
                    ticketName: stop.ticketName || stop.name || stop.frontendTitle,
                    name: `${stop.frontendTitle}: ${stop.frontendSubtitle}`,
                    selectedFromStopId: direction === 'from' ? (currentMarker.linkName ? currentMarker.linkName : currentMarker.id) : false,
                    selectedToStopId: direction === 'to' ? (currentMarker.linkName ? currentMarker.linkName : currentMarker.id) : false
                  });

                  duplicates.push(stop.id);
                }
              } else {
                if (!duplicates.includes(stop.id)) {
                  tempMarkers.push({
                    ...stop,
                    id: stop.id,
                    routeId: [route.id],
                    ticketName: stop.ticketName || stop.name || stop.frontendTitle,
                    name: `${stop.frontendTitle}: ${stop.frontendSubtitle}`,
                    selectedFromStopId: currentMarker[0].linkName ? currentMarker[0].linkName : currentMarker[0].id,
                    selectedToStopId: currentMarker[1].linkName ? currentMarker[1].linkName : currentMarker[1].id
                  });

                  duplicates.push(stop.id);
                } else {
                  let duplicateStop = tempMarkers.filter(thisStop => thisStop.id === stop.id);
                  if (duplicateStop.length)
                    duplicateStop[0].routeId.push(route.id);
                }
              }
            });
        });

      this.setState(
        {
          markers: tempMarkers,
          polylines: tempPolylines,
          currentMarker: direction !== 'between' ? [currentMarker] : currentMarker,
          activeRoute:
            direction !== 'between'
              ? null
              : tempPolylines.length
              ? this.getPolylineInfo(tempPolylines)
              : null,
          isBetween: direction !== 'between' ? false : true,
          direction: direction
        },
        () => this.getBounds()
      );
    }
  };

  getBounds = () => {
    const group = this.groupRef.leafletElement.getBounds();
    this.setFitBounds(group);
  };

  setFitBounds = bounds => {
    if ((bounds && bounds.length && bounds[0][0]) || (typeof(bounds) === "object" && bounds._northEast)) {
      const map = this.mapRef.leafletElement;
      this.polylineRef && this.polylineRef.leafletElement.bringToFront();
      map.fitBounds(bounds, {
        padding: [30, 30]
      });
    }
  };

  getPolylineInfo = polylines => {
    const { positions, ...others } = polylines[polylines.length - 1];
    return others;
  };

  nextRoute = event => {
    event.preventDefault();
    const { polylines, activeRoute } = this.state;
    let currentIndex = polylines.findIndex(polyline => polyline.id === activeRoute.id);
    if (currentIndex < polylines.length - 1) {
      currentIndex++;
    } else {
      currentIndex = 0;
    }
    this.polylineRefs[polylines[currentIndex].id].fireLeafletEvent('click');
  };

  prevRoute = event => {
    event.preventDefault();
    const { polylines, activeRoute } = this.state;
    let currentIndex = polylines.findIndex(polyline => polyline.id === activeRoute.id);
    if (currentIndex > 0) {
      currentIndex--;
    } else {
      currentIndex = polylines.length - 1;
    }
    this.polylineRefs[polylines[currentIndex].id].fireLeafletEvent('click');
  };

  setActiveRoute = id => {
    this.setState(({ polylines }) => {
      const currentPolyline = polylines.filter(polyline => polyline.id === id);

      return {
        activeRoute: this.getPolylineInfo(currentPolyline),
        polylines: polylines
      };
    });
  };

  togglePolylines = () => {
    this.setState(({ isShowingPolylines }) => ({
      isShowingPolylines: !isShowingPolylines
    }));
  };

  render() {
    this.polylineRefs = [];
    const { match } = this.props;
    const {
      markers,
      polylines,
      currentMarker,
      activeRoute,
      isBetween,
      isShowingPolylines,
      direction
    } = this.state;

    return (
      <Fragment>
        {match.params.from && !match.params.to && currentMarker[0] && (
          <Helmet titleTemplate={`Services from, ${currentMarker[0].ticketName}  - %s`} >
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        )}
        {!match.params.from && match.params.to && currentMarker[0] && (
          <Helmet titleTemplate={`Services to, ${currentMarker[0].ticketName}  - %s`} >
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        )}
        {match.params.from &&
          match.params.to &&
          currentMarker[0] &&
          currentMarker[1] &&
          !this.props.error && (
            <Helmet
              titleTemplate={`Services between, ${currentMarker[0].ticketName} and ${currentMarker[1].ticketName} - %s`}
            >
              <meta name="robots" content="noindex,nofollow" />
            </Helmet>
          )}
        {match.params.from &&
          match.params.to &&
          currentMarker[0] &&
          currentMarker[1] &&
          this.props.error && (
            <Helmet
              titleTemplate={`No Services between, ${currentMarker[0].ticketName} and ${currentMarker[1].ticketName} - %s`}
            >
              <meta name="robots" content="noindex,nofollow" />
            </Helmet>
          )}

        <HeaderMap
          isBetween={isBetween}
          polylines={polylines}
          activeRoute={activeRoute}
          isShowingPolylines={isShowingPolylines}
          togglePolylines={this.togglePolylines}
          nextRoute={this.nextRoute}
          prevRoute={this.prevRoute}
        />

        <MapWithProvider
          viewport={DEFAULT_VIEWPORT}
          maxZoom={MAX_ZOOM}
          boundsOptions={{ padding: [30, 30] }}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
          className={`markercluster-map ${isBetween ? 'markercluster-map--between' : ''}`}
          ref={ref => (this.mapRef = ref)}
          onLocationFound={e => this.onLocationFound(e)}
        >
          <FeatureGroup ref={ref => (this.groupRef = ref)}>
            {markers.map(stop => (
                <CustomMarker
                  key={v4()}
                  zIndexOffset={899}
                  icon={getCategoryIconMarker(
                    activeRoute && stop.routeId.includes(activeRoute.id) && stop.category === 1
                      ? 'selectedStop'
                      : stop.category
                  )}
                  stop={stop}
                  {...this.props}
                />
            ))}

            {isShowingPolylines && polylines.length &&
              polylines.map(polyline => (
                polyline.positions.length &&
                <Polyline
                  ref={
                    activeRoute && polyline.id === activeRoute.id
                      ? ref => (this.polylineRef = ref)
                      : ref => (this.polylineRefs[polyline.id] = ref)
                  }
                  key={polyline.id}
                  id={polyline.id}
                  positions={polyline.positions}
                  color={activeRoute && polyline.id === activeRoute.id ? '#00BF51' : '#4D6879'}
                  weight={activeRoute && polyline.id === activeRoute.id ? 6 : 3}
                  opacity={activeRoute && polyline.id === activeRoute.id ? 1 : 0.4}
                  onClick={({ target }) => {
                    if (isBetween) {
                      target.bringToFront();
                      this.setActiveRoute(target.options.id);
                    }
                  }}
                />
              ))}

            {currentMarker.map((stop, index) => (
              <CustomMarker
                key={v4()}
                icon={getCategoryIconMarker(index === 1 || direction === 'to' ? 'destination' : 'selected')}
                zIndexOffset={999}
                stop={stop}
                {...this.props}
              />
            ))}
          </FeatureGroup>
        </MapWithProvider>
      </Fragment>
    );
  }
}

const HeaderMap = ({
  isBetween,
  polylines,
  activeRoute,
  isShowingPolylines,
  togglePolylines,
  nextRoute,
  prevRoute
}) => {
  const [isShowingBanner, setToggle] = useState(true);

  const toggleHandle = () => {
    setToggle(!isShowingBanner);
  };

  return (
    <Fragment>
      {isBetween ? (
        <div className="route-finder-map-header route-finder-map-header--between">
          {activeRoute && (
            <div className="wrapper">
              <div className="route-info-wrapper">
                <div className="route-number">{activeRoute.name}</div>
                <div className="route-toward">
                  <span>towards</span>
                  <b>{activeRoute.arrival}</b>
                </div>
              </div>
              <div className="route-left">
                <div className="route-duration">
                  <span>Duration: {convertMinsToHrsMins(activeRoute.duration)}</span>
                </div>
                {polylines.length > 1 && (
                  <div className="control-buttons">
                    <button onClick={prevRoute}>{'<< Prev'}</button>|
                    <button onClick={nextRoute}>{'Next >>'}</button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="route-finder-map-header route-finder-map-header--not-between">
          <div className="wrapper">
            <button className="button button--grey hidenetwork" onClick={togglePolylines}>
              {isShowingPolylines ? 'Hide Routes' : 'Show Routes'}
            </button>

            {isShowingBanner && (
              <div className="banner">
                <div>
                  <p>Displaying only Direct Journeys.</p>
                  <p>
                    For connections please search on our{' '}
                    <a
                      href="https://www.nationalexpress.com/en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      homepage
                    </a>
                    .
                  </p>
                  <div className="close-btn" onClick={toggleHandle}>
                    <img src={iconClearInput} alt="close" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(withTracker(SearchServiceMap));
