import PopupActionTypes from './popup.types';

const INITIAL_STATE = {
  feedbackPopupHidden: true
};

const popupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PopupActionTypes.TOGGLE_FEEDBACK_POPUP_HIDDEN:
      return {
        ...state,
        feedbackPopupHidden: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default popupReducer;
