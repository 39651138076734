import React from 'react';
import ReactPopup from 'reactjs-popup';

import { EMBED } from '../configs/global';

import IconServiceStop from '../assets/images/icon-components/IconServiceStop.svg';
import IconCoachStop from '../assets/images/icon-components/IconCoachStop.svg';
import IconLocationPin from '../assets/images/icon-components/IconLocationPin.svg';
import IconStopCluster from '../assets/images/icon-components/IconStopCluster.svg';
import IconCoachStation from '../assets/images/icon-components/IconCoachStation.svg';
import IconAirportServices from '../assets/images/icon-components/IconAirportServices.svg';
import IconEventServices from '../assets/images/icon-components/IconEventServices.svg';
import IconWheelChair from '../assets/images/icon-components/IconWheelChair.svg';
import IconGreenStop from '../assets/images/icon-components/IconGreenStop.svg';
import IconBlueStop from '../assets/images/icon-components/IconBlueStop.svg';

import IconTooltipInfoBtn from '../assets/images/route-finder/tooltip-info-btn-icon.svg';
import IconClosePopupBtn from '../assets/images/icon-components/IconClosePopup';

import useWindowSize from '../hooks/useWindowSize';

const RouteFinderPopupInfo = () => {
  const size = useWindowSize();

  return (
    <div className="route-finder-tooltip-info">
      <ReactPopup
        trigger={
          <div className={`tooltip-info-icon ${!EMBED ? '' : 'tooltip-info-icon--embed'}`}>
            <img src={IconTooltipInfoBtn} alt="Tooltip Info" />
          </div>
        }
        modal
        closeOnDocumentClick={size.width > 1024 ? false : true}
        className="toopltip-info-popup"
      >
        {close => (
          <div className="toopltip-info-content">
            <div className="title">
              <span>Key</span>
              <button onClick={close}>
                <IconClosePopupBtn />
              </button>
            </div>

            <ul>
              <li>
                <div>
                  <img src={IconCoachStop} alt="Coach Stop" />
                </div>
                <span>Coach Stop</span>
              </li>
              <li>
                <div>
                  <img src={IconServiceStop} alt="Service Stop" />
                </div>
                <span>Service Stop</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconLocationPin} alt="Location Pin Drop" />
                </div>
                <span>Location Pin Drop</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconStopCluster} alt="Stop Cluster" />
                </div>
                <span>Stop Cluster</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconGreenStop} alt="Travelling From" />
                </div>
                <span>Travelling From</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconBlueStop} alt="Travelling To" />
                </div>
                <span>Travelling To</span>
              </li>

              <li>
                <div>
                  <img className="largeIcon" src={IconCoachStation} alt="Coach Station" />
                </div>
                <span>Coach Station</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconAirportServices} alt="Airport Services Only" />
                </div>
                <span>Airport Services Only</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconEventServices} alt="Event Services Only" />
                </div>
                <span>Event Services Only</span>
              </li>
              <li>
                <div>
                  <img className="largeIcon" src={IconWheelChair} alt="Wheelchair Accessible" />
                </div>
                <span>Wheelchair Accessible</span>
              </li>
            </ul>
          </div>
        )}
      </ReactPopup>
    </div>
  );
};

export default RouteFinderPopupInfo;
