import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import rootReducer from './root-reducer';

const middlewares = applyMiddleware(promise, thunk);

const store = createStore(rootReducer, composeWithDevTools(middlewares));

export default store;
