import React from 'react';

export const highlightEnteredText = (text, search) => {
  if (text && search) {
    const pos = text.toLowerCase().indexOf(search.toLowerCase());

    if (pos >= 0) {
      return (
        <div>
          {text.substring(0, pos)}
          <span>{text.substring(pos, pos + search.length)}</span>
          {text.substring(pos + search.length)}
        </div>
      );
    }
    return text;
  }
  return text;
};
