import L from 'leaflet';

import { EMBED } from '../configs/global';

import iconMapCoachStation from '../assets/images/route-finder/map-coach-icon.svg';
import iconMapSelectedCoachStation from '../assets/images/route-finder/map-coach-stop-tall-icon.svg';
import iconStop from '../assets/images/route-finder/key-stop-icon.svg';
import iconAirportServices from '../assets/images/route-finder/key-airport-services-icon.svg';
import iconEventsServices from '../assets/images/route-finder/key-events-services-icon.svg';
import iconMapSelectedStop from '../assets/images/route-finder/map-selected-stop-icon.svg';
import iconMapDestinationStop from '../assets/images/route-finder/map-destination-stop-icon.svg';
import iconServiceStop from '../assets/images/route-finder/service-stop-icon.svg';
import iconPin from '../assets/images/route-finder/map-marker.svg'

const IconSizeSmall = !EMBED ? 14 : 10;
const IconSizeSmallY = !EMBED ? 26 : 22;
const IconSizeMedium = !EMBED ? 40 : 33;
const LolIconSizeLargeX = !EMBED ? 29 : 24;
const LolIconSizeLargeY = !EMBED ? 46 : 38;
const NewIconSizeLargeX = !EMBED ? 35 : 29;
const NewIconSizeLargeY = !EMBED ? 46 : 38;

const IconMarkerStop = new L.Icon({
  iconUrl: iconStop,
  iconRetinaUrl: iconStop,
  iconAnchor: null,
  popupAnchor: [0, -7],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(IconSizeSmall, IconSizeSmall)
});

const IconMarkerCoachStation = new L.Icon({
  iconUrl: iconMapCoachStation,
  iconRetinaUrl: iconMapCoachStation,
  iconAnchor: [NewIconSizeLargeX/2, NewIconSizeLargeY],
  popupAnchor: [0, -NewIconSizeLargeY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(NewIconSizeLargeX, NewIconSizeLargeY)
});

const IconMarkerSelectedCoachStation = new L.Icon({
  iconUrl: iconMapSelectedCoachStation,
  iconRetinaUrl: iconMapSelectedCoachStation,
  iconAnchor: [IconSizeSmall/2, IconSizeSmallY],
  popupAnchor: [0, -IconSizeSmallY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(IconSizeSmall, IconSizeSmallY)
});

const IconMarkerAirport = new L.Icon({
  iconUrl: iconAirportServices,
  iconRetinaUrl: iconAirportServices,
  iconAnchor: [NewIconSizeLargeX/2, NewIconSizeLargeY],
  popupAnchor: [0, -NewIconSizeLargeY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(NewIconSizeLargeX, NewIconSizeLargeY)
});

const IconMarkerEvent = new L.Icon({
  iconUrl: iconEventsServices,
  iconRetinaUrl: iconEventsServices,
  iconAnchor: [NewIconSizeLargeX/2, NewIconSizeLargeY],
  popupAnchor: [0, -NewIconSizeLargeY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(NewIconSizeLargeX, NewIconSizeLargeY)
});

const IconMarkerService = new L.Icon({
  iconUrl: iconServiceStop,
  iconRetinaUrl: iconServiceStop,
  iconAnchor: null,
  popupAnchor: [0, -11],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(IconSizeSmall + 3, IconSizeSmall + 3)
});

const IconMapSelectedStop = new L.Icon({
  iconUrl: iconMapSelectedStop,
  iconRetinaUrl: iconMapSelectedStop,
  iconAnchor: [LolIconSizeLargeX/2, LolIconSizeLargeY],
  popupAnchor: [0, -LolIconSizeLargeY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(LolIconSizeLargeX, LolIconSizeLargeY)
});

const IconMapDestinationStop = new L.Icon({
  iconUrl: iconMapDestinationStop,
  iconRetinaUrl: iconMapDestinationStop,
  iconAnchor: [LolIconSizeLargeX/2, LolIconSizeLargeY],
  popupAnchor: [0, -LolIconSizeLargeY],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(LolIconSizeLargeX, LolIconSizeLargeY)
});

const IconPin = new L.Icon({
  iconUrl: iconPin,
  iconRetinaUrl: iconPin,
  iconAnchor: null,
  popupAnchor: [0, -30],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(IconSizeMedium, IconSizeMedium)
});

export const getCategoryIconMarker = category => {
  switch (category) {
    case 1:
      return IconMarkerStop;
    case 2:
      return IconMarkerCoachStation;
    case 5:
      return IconMarkerAirport;
    case 6:
      return IconMarkerService;
    case 7:
      return IconMarkerEvent;
    case 'selected':
      return IconMapSelectedStop;
    case 'destination':
      return IconMapDestinationStop;
    case 'selectedStop':
      return IconMarkerSelectedCoachStation;
    case 'iconPin':
      return IconPin;
    default:
      return IconMarkerStop;
  }
};

export const getCircleTooltipLatLng = (latlng, distance) => {
  let earth = 6378.137,
      pi = Math.PI,
      cos = Math.cos,
      m = (1 / ((2 * pi / 360) * earth)) / 1000;

  let latitude = latlng.lat ? latlng.lat : latlng[0];
  let longitude = (latlng.lng ? latlng.lng : latlng[1]) + (distance * m) / cos(latitude * (pi / 180));

  return [latitude, longitude];
}