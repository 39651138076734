import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
    <path
      id="coachtracker_icon"
      data-name="coachtracker icon"
      d="M12,11.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Z"
      transform="translate(-5 -2)"
      fill="#8e8e8e"
    />
  </svg>
);
