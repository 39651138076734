import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import fetchJsonp from 'fetch-jsonp';
import { v4 } from 'uuid';

import SearchLeftPanel from '../../components/SearchLeftPanel';

import { highlightEnteredText } from '../../utils/input';
import { SHORT_API_URL, LONG_API_URL } from '../../constants/common';

import iconClearInput from '../../assets/images/clear-search-icon.svg';
import iconChangeDirection from '../../assets/images/route-finder/change-direction-icon.svg';
import iconDanger from '../../assets/images/danger.svg';

const wrapperStyle = {
  width: '100%',
  position: 'relative'
};

const menuStyle = {
  background: 'transparent',
  left: 0,
  top: 55,
  position: 'absolute',
  maxHeight: 250,
  overflowY: 'auto',
  zIndex: 1002
};

const changeDirectionStyle = {
  position: 'absolute',
  top: '35px',
  right: '35px',
  cursor: 'pointer',
  zIndex: 2
};

const initStationObj = {
  linkName: null,
  isSelected: false,
  value: '',
  stations: []
};

const InputError =
  'Sorry, there are no services that operate between the stops chosen. Please try again by typing a Postal code or alternative location.';

class Search extends Component {
  state = {
    stationFrom: initStationObj,
    stationTo: initStationObj
  };

  componentDidUpdate(prevProps) {
    const { fromData, toData } = this.props;
    const { stationFrom, stationTo } = this.state;

    if (fromData && (prevProps.fromData !== fromData || prevProps.fromData.linkName !== fromData.linkName)) {
      this.setState({
        stationFrom: {
          linkName: fromData.linkName,
          isSelected: true,
          value: fromData.name,
          stations: []
        }
      });
    } else if (!fromData && !prevProps.fromData && stationFrom && stationFrom.linkName) {
      this.setState({
        stationFrom: initStationObj
      });
    }

    if (toData && (prevProps.toData !== toData || prevProps.toData.linkName !== toData.linkName)) {
      this.setState({
        stationTo: {
          linkName: toData.linkName,
          isSelected: true,
          value: toData.name,
          stations: []
        }
      });
    } else if (!toData && !prevProps.toData && stationTo && stationTo.linkName) {
      this.setState({
        stationTo: initStationObj
      });
    }
  }

  fetchSearchStops = (lookup, direction) => {
    fetchJsonp(`${SHORT_API_URL}/search?lookup=${lookup}&mode%5B%5D=route_finder&limit=10`)
      .then(response => response.json())
      .then(response => {
        this.setState(prevState => ({
          [direction]: {
            ...prevState[direction],
            isSelected: false,
            stations: response.status !== 'ERROR' ? response : []
          }
        }));
      })
      .catch(error => console.error(error));
  };

  fetchNearestStops = (latitude, longitude, isSearchSelect = false) => {
    const fixedLatitude = latitude.toFixed(5);
    const fixedLongitude = longitude.toFixed(5);

    fetchJsonp(
      `${LONG_API_URL}/search?lookup=${fixedLatitude},${fixedLongitude}&mode%5B%5D=nearest_stop&${
        isSearchSelect ? 'distance=8000,16000,40000' : 'limit=8'
      }`
    )
      .then(response => response.json())
      .then(response => {
        if (response) {
          const getBounds = response.map(stop => [stop.latitude, stop.longitude]);

          this.props.setNewPositionAccordingToSearch(getBounds);
        }
      })
      .catch(error => console.error(error));
  };

  handleChange = (direction, value) => {
    this.setState(
      prevState => ({
        [direction]: { ...prevState[direction], value: value }
      }),
      () => {
        if (value.length > 2) {
          this.fetchSearchStops(value, direction);
        }
      }
    );
  };

  handleSelect = (direction, value, item) => {
    this.setState(
      prevState => ({
        [direction]: { ...prevState[direction], isSelected: false, value: value }
      }),
      () => {
        const { latitude, longitude } = item;

        this.fetchNearestStops(latitude, longitude);
      }
    );
  };

  handleClear = direction => {
    this.setState(
      prevState => ({
        [direction]: { ...prevState[direction], isSelected: false, value: '' }
      }),
      () => {
        const { stationFrom, stationTo } = this.state;
        const { history } = this.props;

        if (!stationFrom.value && !stationTo.value) {
          history.push('/');
        } else if (stationFrom.value && !stationTo.value) {
          history.push(`/search/from/${stationFrom.linkName}`);
        } else if (!stationFrom.value && stationTo.value) {
          history.push(`/search/to/${stationTo.linkName}`);
        }
      }
    );
  };

  handleChangeDirection = () => {
    const { stationFrom, stationTo } = this.state;
    const { history } = this.props;

    history.push(`/search/between/${stationTo.linkName}/${stationFrom.linkName}`);
  };

  handleBookJourney = event => {
    event.preventDefault();

    const { fromData, toData, result } = this.props;
    const { date, departTime, operatorId } = result[0];
    const today = new Date(date);
    const departureDate = today.toLocaleDateString('en-GB').replace(/[.]/g, '/');
    let adultType = 'adults=1';
    if (fromData.bookingId.charAt(0).match(/[a-z]/i) || fromData.bookingId.charAt(fromData.bookingId.length - 1).match(/[a-z]/i) || toData.bookingId.charAt(0).match(/[a-z]/i) || toData.bookingId.charAt(toData.bookingId.length - 1).match(/[a-z]/i))
      adultType = 'euroAdults=1';
    const URL = `https://book.nationalexpress.com/coach/#/campaign?journeyType=single&departArrive=DepartAfter&partnerId=uTrack&campaignId=default&returnDate=&departArriveReturn=DepartAfter&returnTime=&departureDate=${departureDate}&outboundTime=${departTime}&departureStopId=${fromData.bookingId}&destinationStopId=${toData.bookingId}&${operatorId === 'OU' ? 'ouiAdults=1' : adultType}`;

    window.open(URL, '_blank');
  };

  checkInputError = () => {
    const { stationFrom, stationTo } = this.state;

    if (
      (stationFrom.value.length > 2 &&
        !stationFrom.isSelected &&
        stationFrom.stations.length === 0) ||
      (stationTo.value.length > 2 && !stationTo.isSelected && stationTo.stations.length === 0)
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { isMobile } = this.props;
    const { stationFrom, stationTo } = this.state;
    const isBetweenMode = stationFrom.isSelected && stationTo.isSelected;

    return (
      <SearchLeftPanel>
        {/* From Input */}
        <div
          style={wrapperStyle}
          className={
            stationFrom.isSelected
              ? 'autocomplete-wrapper-selected autocomplete-wrapper-selected--from'
              : ''
          }
        >
          {stationFrom.isSelected && <div className='from-label'>From:</div>}
          <Autocomplete
            items={
              stationFrom.value.length > 2
                ? stationFrom.stations.map(item => ({ key: v4(), label: item.name, ...item }))
                : []
            }
            shouldItemRender={(item, value) =>
              item.label
                .toLowerCase()
                .replace(/ /g, '')
                .indexOf(value.toLowerCase()) > -1 ||
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.label}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.key}
                className={
                  isHighlighted ? 'autocomplete autocomplete--highlighted' : 'autocomplete'
                }
              >
                {highlightEnteredText(item.label, stationFrom.value)}
              </div>
            )}
            value={stationFrom.value}
            onChange={e => this.handleChange('stationFrom', e.target.value)}
            onSelect={(value, item) => {
              this.handleSelect('stationFrom', value, item);
            }}
            inputProps={{ placeholder: stationTo.value ? 'Search for your starting location' : 'Location or postal code', disabled: false, maxLength: 99 }}
            wrapperStyle={{ width: '100%' }}
            menuStyle={isMobile ? { ...menuStyle, maxHeight: 200 } : menuStyle}
          />

          {stationFrom.value && (
            <img
              src={iconClearInput}
              alt="Clear input"
              className="coach-search-clearable coach-search-clearable--mobile"
              style={{ top: '14px' }}
              onClick={() => this.handleClear('stationFrom')}
            />
          )}
        </div>

        {isBetweenMode && (
          <img
            src={iconChangeDirection}
            style={changeDirectionStyle}
            alt="Change direction"
            onClick={this.handleChangeDirection}
          />
        )}

        {/* To Input */}
        <div
          style={wrapperStyle}
          className={
            stationTo.isSelected
              ? 'autocomplete-wrapper-selected autocomplete-wrapper-selected--to'
              : ''
          }
        >
          {stationTo.isSelected && <div className='to-label'>To:</div>}
          <Autocomplete
            items={
              stationTo.value.length > 2
                ? stationTo.stations.map(item => ({ key: v4(), label: item.name, ...item }))
                : []
            }
            shouldItemRender={(item, value) =>
              item.label
                .toLowerCase()
                .replace(/ /g, '')
                .indexOf(value.toLowerCase()) > -1 ||
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.label}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.key}
                className={
                  isHighlighted ? 'autocomplete autocomplete--highlighted' : 'autocomplete'
                }
              >
                {highlightEnteredText(item.label, stationTo.value)}
              </div>
            )}
            value={stationTo.value}
            onChange={e => this.handleChange('stationTo', e.target.value)}
            onSelect={(value, item) => {
              this.handleSelect('stationTo', value, item);
            }}
            inputProps={{ placeholder: stationFrom.value ? 'Search and select your destination' : 'Location or postal code', disabled: false, maxLength: 99 }}
            wrapperStyle={{ width: '100%' }}
            menuStyle={isMobile ? { ...menuStyle, maxHeight: 200 } : menuStyle}
          />

          {stationTo.value && (
            <img
              src={iconClearInput}
              alt="Clear input"
              className="coach-search-clearable coach-search-clearable--mobile"
              style={{ top: '14px' }}
              onClick={() => this.handleClear('stationTo')}
            />
          )}
        </div>

        {(this.props.error || this.checkInputError()) && (
          <div className="search-service-page--error">
            <div className="search-service-page--error__image">
              <img src={iconDanger} alt="error" />
            </div>
            <div>{this.props.error || InputError}</div>
          </div>
        )}

        {isBetweenMode && (
          <div className="search-service-page--book-journey">
            <button
              className="button"
              onClick={this.handleBookJourney}
              disabled={this.props.error || this.checkInputError()}
            >
              Book Journey
            </button>
          </div>
        )}
      </SearchLeftPanel>
    );
  }
}

export default withRouter(Search);
