import { IS_MOBILE_SCREEN, IS_DETAIL_VIEW } from './mobile.actions';

const initialState = {
  open: false,
  detailView: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case IS_MOBILE_SCREEN:
      return {
        ...state,
        ...action.payload
      };
    case IS_DETAIL_VIEW:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
}
