import { combineReducers } from 'redux';

import mobileScreen from './mobile/mobile.reducer';
import popupReducer from './popup/popup.reducer';

const rootReducer = combineReducers({
  mobileScreen,
  popupReducer
});

export default rootReducer;
