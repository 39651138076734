import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.073"
    height="17.248"
    viewBox="0 0 12.073 17.248"
  >
    <g id="tooltip_map-marker" data-name="tooltip map-marker" transform="translate(0 0)">
      <path
        id="map-marker"
        d="M11.037,10.193a2.156,2.156,0,1,1,2.156-2.156,2.156,2.156,0,0,1-2.156,2.156m0-8.193A6.037,6.037,0,0,0,5,8.037c0,4.527,6.037,11.211,6.037,11.211s6.037-6.683,6.037-11.211A6.037,6.037,0,0,0,11.037,2Z"
        transform="translate(-5 -2)"
        fill="#0175bf"
      />
    </g>
  </svg>
);
