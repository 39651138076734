export const IS_MOBILE_SCREEN = 'IS_MOBILE_SCREEN';
export const IS_DETAIL_VIEW = 'IS_DETAIL_VIEW';

export const isMobileScreen = isMobile => dispatch =>
  dispatch({
    type: IS_MOBILE_SCREEN,
    payload: isMobile
  });

export const isDetailView = isDetail => dispatch =>
  dispatch({
    type: IS_DETAIL_VIEW,
    payload: isDetail
  });
