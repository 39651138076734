import React from 'react';

export default () => (
  <svg
    id="delete_button"
    data-name="delete button"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <circle id="Ellipse_197" data-name="Ellipse 197" cx="8.5" cy="8.5" r="8.5" fill="#bfbfbf" />
    <line
      id="Line_186"
      data-name="Line 186"
      x2="6.119"
      y2="6.119"
      transform="translate(5.624 5.624)"
      fill="none"
      stroke="#414141"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <line
      id="Line_187"
      data-name="Line 187"
      y1="6.119"
      x2="6.119"
      transform="translate(5.624 5.624)"
      fill="none"
      stroke="#414141"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);
