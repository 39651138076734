import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.691"
    height="15.038"
    viewBox="0 0 17.691 15.038"
  >
    <path
      id="safety_toolbox_icon"
      data-name="safety toolbox icon"
      d="M17.922,16.269V12.73H15.269v.885H13.5V12.73H8.192v.885H6.423V12.73H3.769v3.538H17.922M5.83,7.423,4.291,10.961H6.423v-.885H8.192v.885H13.5v-.885h1.769v.885H17.4L15.861,7.423H5.83M8.192,4.769v.885H13.5V4.769H8.192M19.55,11.5a1.679,1.679,0,0,1,.142.708v4.06a1.773,1.773,0,0,1-.531,1.247,1.665,1.665,0,0,1-1.238.522H3.769a1.665,1.665,0,0,1-1.238-.522A1.773,1.773,0,0,1,2,16.269v-4.06a1.679,1.679,0,0,1,.142-.708l2.07-4.768A1.627,1.627,0,0,1,5.83,5.654h.593V4.769A1.752,1.752,0,0,1,8.192,3H13.5a1.752,1.752,0,0,1,1.769,1.769v.885h.593A1.627,1.627,0,0,1,17.48,6.733Z"
      transform="translate(-2 -3)"
      fill="#8e8e8e"
    />
  </svg>
);
