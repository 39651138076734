import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.127"
    height="13.702"
    viewBox="0 0 17.127 13.702"
  >
    <path
      id="tickets_icon"
      data-name="tickets icon"
      d="M3.713,4A1.713,1.713,0,0,0,2,5.713V9.138a1.713,1.713,0,1,1,0,3.425v3.425A1.713,1.713,0,0,0,3.713,17.7h13.7a1.713,1.713,0,0,0,1.713-1.713V12.563a1.713,1.713,0,1,1,0-3.425V5.713A1.713,1.713,0,0,0,17.414,4H3.713m0,1.713h13.7V7.888a3.419,3.419,0,0,0,0,5.926v2.175H3.713V13.814a3.419,3.419,0,0,0,0-5.926Z"
      transform="translate(-2 -4)"
      fill="#797979"
    />
  </svg>
);
