import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <style>{`.a{fill:#bfbfbf;}.b{fill:none;stroke:#414141;stroke-linecap:round;stroke-width:2px;}`}</style>
    </defs>
    <circle className="a" cx="13" cy="13" r="13" />
    <line className="b" x2="9.503" y2="9.503" transform="translate(8.332 8.734)" />
    <line className="b" y1="9.503" x2="9.503" transform="translate(8.332 8.734)" />
  </svg>
);
