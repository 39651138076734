import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.127"
    height="17.127"
    viewBox="0 0 17.127 17.127"
  >
    <path
      id="feedback_icon"
      data-name="feedback icon"
      d="M11.42,8.851H9.707V5.425H11.42V8.851m0,1.713H9.707v1.713H11.42V10.564m7.707-6.851V13.989A1.713,1.713,0,0,1,17.415,15.7H5.425L2,19.127V3.713A1.713,1.713,0,0,1,3.713,2h13.7a1.713,1.713,0,0,1,1.713,1.713m-1.713,0H3.713v11.3L4.74,13.989H17.415Z"
      transform="translate(-2 -2)"
      fill="#8e8e8e"
    />
  </svg>
);
