import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Timetables_icons"
      data-name="Timetables icons"
      d="M12,20a8,8,0,1,0-8-8,8,8,0,0,0,8,8M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2m.5,5v5.25L17,14.92l-.75,1.23L11,13V7Z"
      transform="translate(-2 -2)"
      fill="#8e8e8e"
    />
  </svg>
);
