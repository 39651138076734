import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { THEME } from './configs/global';

import IndexPage from './containers/IndexPage';

const App = () => (
  <Router>
    <div className={`App ${THEME.NAME.toUpperCase()}`}>
      <Helmet title={THEME.TITLE} defaultTitle={THEME.TITLE} />

      <Route path="/" component={IndexPage} />
    </div>
  </Router>
);

export default App;
