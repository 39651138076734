import React, { Fragment } from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';

import Header from '../components/Header';
import PopupFeedback from '../components/PopupFeedback';

import RouteFinder from './search-route';
import SearchService from './search-service';
import ViewRoute from './view-route';

import { EMBED } from '../configs/global';

const IndexPage = props => (
  <Fragment>
    {!EMBED && (
      <Header isRouteFinderPage={props.location.pathname === '/'} logoImage={'route-finder'} />
    )}

    <div style={{ backgroundColor: '#F1F1F1' }}>
      <div
        className="main main--with-results"
        style={{ minHeight: !EMBED ? 'calc(100vh - 64px)' : '100vh' }}
      >
        <Switch>
          <Route path="/" exact render={props => <RouteFinder {...props} />} />
          <Route path="/nearest/:location/:zoom?" render={props => <RouteFinder {...props} />} />

          <Route path="/search/from/:from" render={props => <SearchService {...props} />} />
          <Route path="/search/to/:to" render={props => <SearchService {...props} />} />
          <Route path="/search/between/:from/:to" render={props => <SearchService {...props} />} />

          <Route path="/view/:route" render={props => <ViewRoute {...props} />} />

          <Redirect to="/" />
        </Switch>
      </div>
    </div>

    <PopupFeedback />
  </Fragment>
);

export default withRouter(IndexPage);
