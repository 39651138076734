import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.781"
    height="19.781"
    viewBox="0 0 19.781 19.781"
  >
    <path
      id="routefinder_icon"
      data-name="routefinder icon"
      d="M10.347,3a7.352,7.352,0,0,1,5.584,12.129l.305.305h.893l5.652,5.652-1.7,1.7-5.652-5.652v-.893l-.305-.305A7.349,7.349,0,1,1,10.347,3m0,2.261a5.087,5.087,0,1,0,5.087,5.087A5.065,5.065,0,0,0,10.347,5.261Z"
      transform="translate(-3 -3)"
      fill="#8e8e8e"
    />
  </svg>
);
