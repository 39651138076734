import React, { Component } from 'react';
import fetchJsonp from 'fetch-jsonp';

import Search from './Search';
import SearchServiceMap from './Map';
import MapWrapperRightPanel from '../../components/MapWrapperRightPanel';

import { LONG_API_URL } from '../../constants/common';
import Modal from 'react-modal';

import IconClose from '../../assets/images/ic-close.svg';

Modal.setAppElement('#root');

const stylesModal = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .4)',
  },
  content: {
    position: 'absolute',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '390px',
    maxWidth: '390px',
    maxHeight: '579px',
    minHeight: '120px',
    padding: '26px 30px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #B6BECC',
    borderRadius: 10,
    boxShadow: '0 0 8px 0 rgba(52,63,82,0.1)',
  },
};

class SearchService extends Component {
  state = {
    fromData: null,
    toData: null,
    stations: [],
    bounds: [],
    error: null,
    isInfoModalOpen: false,
    modalError: null
  };

  toggleInfoModal = (e) => {
    this.setState({
      isInfoModalOpen: !this.state.isInfoModalOpen,
    });
  };

  closeModal = (e) =>
    this.setState({
      isInfoModalOpen: false,
    });

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.switchFetchRequest(params);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;

    if (prevProps.match.params !== params) {
      this.switchFetchRequest(params);
    }
  }

  switchFetchRequest = params => {
    if (params.from && !params.to) {
      this.fetchRoutesFrom(params.from);
    } else if (!params.from && params.to) {
      this.fetchRoutesTo(params.to);
    } else if (params.from && params.to) {
      this.fetchRoutesBetween(params.from, params.to);
    }
  };

  fetchRoutesFrom = fromID => {
    fetchJsonp(`${LONG_API_URL}/routes/from/${fromID}`)
      .then(response => response.json())
      .then(response => {
        if (response.status==='ERROR') {
          this.setState({
            modalError: response.message
          })
          this.toggleInfoModal();
        }
        this.setState({
          fromData: response.request[0],
          stations: response.result,
          toData: null,
          error: null
        });
      })
      .catch(error => console.error(error));
  };

  fetchRoutesTo = toID => {
    fetchJsonp(`${LONG_API_URL}/routes/to/${toID}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          toData: response.request[0],
          stations: response.result,
          fromData: null,
          error: null
        });
      })
      .catch(error => console.error(error));
  };

  fetchRoutesBetween = (fromID, toID) => {
    fetchJsonp(`${LONG_API_URL}/routes/between/${fromID}/${toID}`)
      .then(response => response.json())
      .then(response => {
        const { num_routes, message, request, result } = response;
        const [fromData, toData] = request;

        this.setState({ fromData, toData, stations: result }, () => {
          if (!num_routes && message) {
            let errorMessage = message;
            if (request[0] && request[1]) {
              errorMessage = `Journeys are not bookable between ${request[0].ticketName} and ${request[1].ticketName}, please try another stop along the route`;
            }

            this.setState({ error: errorMessage });
          }
        });
      })
      .catch(error => console.error(error));
  };

  setNewPositionAccordingToSearch = bounds => {
    this.setState({ bounds: bounds });
  };

  render() {
    const { isMobile } = this.props;
    const { fromData, toData, stations, bounds, error, isInfoModalOpen, modalError } = this.state;

    return (
      <div className="route-finder search-service-page">
        <Search
          isMobile={isMobile}
          fromData={fromData}
          toData={toData}
          result={stations}
          error={error}
          setNewPositionAccordingToSearch={this.setNewPositionAccordingToSearch}
        />

        <MapWrapperRightPanel>
          <SearchServiceMap
            fromData={fromData}
            toData={toData}
            stations={stations}
            bounds={bounds}
            error={error}
          />
        </MapWrapperRightPanel>
        <Modal
             shouldCloseOnOverlayClick={true}
             isOpen={isInfoModalOpen}
             onRequestClose={this.closeModal}
             style={stylesModal}
             className="ReactModal__Content--ticket"
           >
             <div className="modal">
               <div className="modal-center">
               <h3 className="modal__title"></h3>
              <p>{modalError ? modalError : 'No routes found serving this stop'}</p>
              <img
                className="modal__close-ticket"
                src={IconClose}
                onClick={this.closeModal}
                alt="Icon Close"
              />
            </div>
          </div>
          <div className="img-email-form"></div>
        </Modal>
      </div>
    );
  }
}

export default SearchService;
