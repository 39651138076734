import React, { Component, Fragment } from 'react';
import Autocomplete from 'react-autocomplete';
import { v4 } from 'uuid';

import SearchLeftPanel from '../../components/SearchLeftPanel';

import { highlightEnteredText } from '../../utils/input';

import iconClearInput from '../../assets/images/clear-search-icon.svg';
import iconLocation from '../../assets/images/route-finder/location-icon.svg';
import iconLocationActive from '../../assets/images/route-finder/location-icon-active.svg';

const wrapperStyle = {
  width: '100%',
  position: 'relative'
};

const menuStyle = {
  background: 'transparent',
  left: 0,
  top: 55,
  position: 'absolute',
  maxHeight: 250,
  overflowY: 'auto',
  zIndex: 1001
};

class RouteFinderSearch extends Component {
  state = {
    inputValue: '',
    stations: []
  };

  componentDidMount() {
    const { match } = this.props;

    if (match.params.location) {
      this.props
      .fetchSearchStops(match.params.location, 1)
      .then(response => {
        if (response.length) {
          this.setState({
            inputValue: response[0].name ? response[0].name : match.params.location
          });
        } else {
          this.setState({ inputValue: match.params.location });
        }
      })
      .catch(error => console.error(error));
    }
  }

  getSearchStops = lookup => {
    this.props
      .fetchSearchStops(lookup)
      .then(response => {
        if (response && (!response.status && response.status !== 'ERROR')) {
          this.setState({
            stations: response,
            inputValue: lookup
          });
        }
      })
      .catch(error => console.error(error));
  };

  handleChange = value => {
    if (value.length > 2) {
      this.getSearchStops(value);
    }
    this.setState({ inputValue: value });
  };

  render() {
    const { isMobile, activeUserLocation } = this.props;
    const { inputValue, stations } = this.state;

    return (
      <SearchLeftPanel>
        <Fragment>
          <Autocomplete
            items={
              inputValue.length > 2
                ? stations.map(item => ({ key: v4(), label: item.name, ...item }))
                : []
            }
            shouldItemRender={(item, value) =>
              item.label
                .toLowerCase()
                .replace(/ /g, '')
                .indexOf(value.toLowerCase()) > -1 ||
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.label}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.key}
                className={
                  isHighlighted ? 'autocomplete autocomplete--highlighted' : 'autocomplete'
                }
              >
                {highlightEnteredText(item.label, inputValue)}
              </div>
            )}
            value={inputValue}
            onChange={e => this.handleChange(e.target.value)}
            onSelect={(value, item) => {
              this.setState({ inputValue: value });
              this.props.handleSearchSelect(item);
              this.props.history.push(`/nearest/${item.linkName ? item.linkName : value}`);
            }}
            inputProps={{ id: "search-input", placeholder: 'Location or postal code', maxLength: 99 }}
            wrapperStyle={wrapperStyle}
            menuStyle={isMobile ? { ...menuStyle, maxHeight: 200 } : menuStyle}
          />

          {inputValue ? (
            <img
              src={iconClearInput}
              alt="Clear input"
              className="coach-search-clearable coach-search-clearable--mobile"
              style={{ top: '14px' }}
              onClick={() => {
                this.setState({ inputValue: '' });
                this.props.resetUserLocation();
              }}
            />
          ) : (
            <img
              src={activeUserLocation ? iconLocationActive : iconLocation}
              alt="Location"
              className="coach-search-clearable coach-search-clearable--mobile"
              style={{ top: '14px' }}
              onClick={
                activeUserLocation
                  ? () => this.props.resetUserLocation()
                  : () => this.props.findUserLocation()
              }
            />
          )}
        </Fragment>
      </SearchLeftPanel>
    );
  }
}

export default RouteFinderSearch;
