import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import fetchJsonp from 'fetch-jsonp';
import L from 'leaflet';
import { Map as LeafletMap } from 'react-leaflet';

import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';

import 'mapbox-gl-leaflet';
import 'mapbox-gl';

import { EMBED } from '../../configs/global';

import CustomMarker from '../../components/Marker';

import { LONG_API_URL, DEFAULT_VIEWPORT, MAX_ZOOM } from '../../constants/common';
import { getCategoryIconMarker } from '../../utils/map';

class MapWithProvider extends LeafletMap {
  createLeafletElement(props) {
    const LeafletMapElement = super.createLeafletElement(props);

    L.control.fullscreen().addTo(LeafletMapElement);

    L.mapboxGL({
      attribution:
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      accessToken: 'pk.eyJ1IjoidXRyYWNrbWFwIiwiYSI6Im9iV245NnMifQ.ddr0eLkRuZUoNVglI8tZvg',
      style: 'https://api.maptiler.com/maps/basic/style.json?key=qFLLNZGl77ciGk4TxKUS'
    }).addTo(LeafletMapElement);

    return LeafletMapElement;
  }
}

class ViewRoute extends Component {
  state = {
    stop: {}
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchViewStop(params.route);
  }

  fetchViewStop = stopID => {
    fetchJsonp(`${LONG_API_URL}/static/stop/${stopID}`)
      .then(response => response.json())
      .then(response => {
        const getBounds = [response].map(stop => [stop.latitude, stop.longitude]);

        this.setState({ stop: response }, () => {
          this.setFitBounds(getBounds);
        });
      })
      .catch(error => console.error(error));
  };

  setFitBounds = bounds => {
    console.log(bounds);
    if (bounds && bounds.length && bounds[0][0]) {
      this.mapRef.leafletElement.fitBounds(bounds, {
        padding: [30, 30]
      });
    }
  };

  render() {
    const { stop } = this.state;

    return (
      <Fragment>
        {stop.id && <Helmet titleTemplate={`View, ${stop.ticketName}  - %s`}>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>}

        <div className="route-finder">
          <div className={!EMBED ? 'main__results' : 'main__results main__results--embed'} style={{ width: '100%' }}>
            <div className="route-finder-map">
              <h3 className="route-finder-map-title">Route Map</h3>

              <MapWithProvider
                viewport={DEFAULT_VIEWPORT}
                maxZoom={MAX_ZOOM}
                attributionControl={true}
                zoomControl={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                dragging={true}
                animate={true}
                easeLinearity={0.35}
                className="markercluster-map"
                ref={ref => (this.mapRef = ref)}
              >

                {stop.id && (
                  <CustomMarker
                    icon={getCategoryIconMarker(stop.category)}
                    stop={stop}
                    isDisabledBtn
                    isOpenMarker
                    {...this.props}
                  />
                )}
              </MapWithProvider>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ViewRoute;
