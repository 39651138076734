import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { isMobileScreen } from '../redux/mobile/mobile.actions';
import { toggleFeedbackPopupHidden } from '../redux/popup/popup.actions';

import IconCoachTrackerMenuItem from '../assets/images/icon-components/IconCoachTrackerMenuItem';
import IconTimetableMenuItem from '../assets/images/icon-components/IconTimetableMenuItem';
import IconRouteFinderMenuItem from '../assets/images/icon-components/IconRouteFinderMenuItem';
import IconSafetyToolboxMenuItem from '../assets/images/icon-components/IconSafetyToolboxMenuItem';
import IconTicketsMenuItem from '../assets/images/icon-components/IconTicketsMenuItem';
import IconFeedbackMenuItem from '../assets/images/icon-components/IconFeedbackMenuItem';
import IconHelpMenuItem from '../assets/images/icon-components/IconHelpMenuItem';

import NXLogoRouteFinder from '../assets/images/header-nx-logo-route-finder.svg';
import NXLogoHorizontal from '../assets/images/NX_logo_horizontal.svg';
import HamburgerWebIcon from '../assets/images/hamburger-menu-web.svg';
import HamburgerMobileIcon from '../assets/images/hamburger-menu-mobile.svg';
import CloseIcon from '../assets/images/close-menu.svg';
import CloseMobileIcon from '../assets/images/close-menu-mobile.svg';

import FacebookIcon from '../assets/images/social-icons/facebook.svg';
import TwitterIcon from '../assets/images/social-icons/twitter.svg';
import InstagramIcon from '../assets/images/social-icons/instagram.svg';
import LinkedinIcon from '../assets/images/social-icons/linkedin.svg';

import ReactHtmlParser from 'react-html-parser'; 

import { THEME } from '../configs/global';

const getThemeLogo = (name, logoImage) => {
  switch (name) {
    case 'NX':
      switch (logoImage) {
        case 'route-finder':
          return NXLogoRouteFinder;

        default:
          return NXLogoRouteFinder;
      }
    default:
      return NXLogoRouteFinder;
  }
};

class Header extends Component {
  static propTypes = {
    hideMap: PropTypes.func,
    clearSearch: PropTypes.func,
    index: PropTypes.bool
  };

  constructor() {
    super();
    this.state = {
      isOpenHamburgerMenu: false
    };

    this.headerMenuRef = React.createRef();
  }

  componentWillMount() {
    const timestamp = JSON.parse(localStorage.getItem('timestamp'));

    if (window.innerWidth <= 740) {
      this.props.isMobileScreen({
        open: true
      });
    }

    if (timestamp) {
      if (new Date().getTime() - timestamp > 172800000) {
        const keys = ['recentSearches', 'ticketSearch', 'timestamp'];
        for (let key of keys) {
          localStorage.removeItem(key);
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  navigateToIndex = e => {
    this.props.hideMap();
    this.props.clearSearch(true);
  };

  handleClickOutside = event => {
    const { isOpenHamburgerMenu } = this.state;

    if (
      isOpenHamburgerMenu &&
      this.headerMenuRef &&
      !this.headerMenuRef.current.contains(event.target)
    ) {
      this.setState({ isOpenHamburgerMenu: false });
    }
  };

  toggleHamburgerMenu = () => {
    this.setState(state => {
      return {
        isOpenHamburgerMenu: !state.isOpenHamburgerMenu
      };
    });
  };

  render() {
    const { isMobile, logoImage } = this.props;
    const { isOpenHamburgerMenu } = this.state;

    return (
      <header className="header">
        <div className="header__container">
          <Link to="/">
            <img src={getThemeLogo(THEME.NAME, logoImage)} alt={THEME.NAME} />
          </Link>

          <div className="header__container__rightside">
            <div className="header__menu__wrapper" ref={this.headerMenuRef}>
              <img
                onClick={this.toggleHamburgerMenu}
                className="header__menu__icon"
                src={isOpenHamburgerMenu ? isMobile ? CloseMobileIcon : CloseIcon : isMobile ? HamburgerMobileIcon : HamburgerWebIcon}
                alt="Menu"
              />

              <div
                className={`header__menu__dropdown header__menu__dropdown--mobile ${
                  isOpenHamburgerMenu
                    ? 'header__menu__dropdown--mobile--open'
                    : 'header__menu__dropdown--mobile--close'
                }`}
              >
                <div className="header__menu__dropdown__title">
                  Select one of our products below:
                </div>

                <ul>
                <li>
                    <a
                      href="https://book.nationalexpress.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconTicketsMenuItem />
                      <span>Book Tickets</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://coachtracker.nationalexpress.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconCoachTrackerMenuItem />
                      <span>Coach Tracker</span>
                    </a>
                  </li>
                  <li>
                    <NavLink to="/" onClick={this.toggleHamburgerMenu} className="active">
                      <IconRouteFinderMenuItem />
                      <span>Route Map</span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://timetables.nationalexpress.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconTimetableMenuItem />
                      <span>Timetables</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.nationalexpress.com/en/help/safety"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconSafetyToolboxMenuItem />
                      <span>Safety Information</span>
                    </a>
                  </li>

                  <li
                    onClick={() => {
                      this.toggleHamburgerMenu();
                      this.props.toggleFeedbackPopupHidden(false);
                    }}
                  >
                    <span className="nav-link">
                      <IconFeedbackMenuItem />
                      <span>Feedback</span>
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://www.nationalexpress.com/en/help/routemap"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconHelpMenuItem />
                      <span>Help</span>
                    </a>
                  </li>
                </ul>

                <div className="header__menu__dropdown--mobile__footer">
                  <div className="header__menu__dropdown--mobile__footer__wrapper">
                    <div className="logo">
                      <img src={NXLogoHorizontal} alt="NX" />
                    </div>

                    <div className="social-links">
                      <a
                        href="https://www.facebook.com/NationalExpress/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={FacebookIcon} alt="Facebook" />
                      </a>
                      <a
                        href="https://twitter.com/nationalexpress"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={TwitterIcon} alt="Twitter" />
                      </a>
                      <a
                        href="https://www.instagram.com/nationalexpress/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={InstagramIcon} alt="Instagram" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/national-express/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={LinkedinIcon} alt="LinkedIn" />
                      </a>
                    </div>

                    <div className="link">
                      <a
                        href="https://www.nationalexpress.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.nationalexpress.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {THEME.SHOW_BANNER && <div className="banner">
          <div className="banner--content">
            {ReactHtmlParser (THEME.BANNER_HTML)}
          </div>
        </div>}

      </header>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.mobileScreen.open
});

export default connect(
  mapStateToProps,
  {
    isMobileScreen,
    toggleFeedbackPopupHidden
  }
)(Header);
